import React, { useContext, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import * as Text from "@/components/text";
import Image from "next/legacy/image";
import apiClient from "@/utils/apiClient";
import useSWR from "swr";
import { GlobalContext } from "../../stores/GlobalContextProvider";

export default function InitiativesPopover() {
  const globalConfiguration = useContext(GlobalContext);
  const [showAllInitiatives, setShowAllInitiatives] = useState(false);
  return (
    <div>
      <div className="grid-cols-2 gap-4 space-y-1 lg:grid lg:space-y-0">
        <InititativeListing
          image="/initiatives/gira.png"
          link="/iniciativa/gira"
          title="Gira de Documentales"
          description="Nuestra gira de cine itinerante"
          accentColor="gira"
        />
        <InititativeListing
          image="/initiatives/presenta.png"
          link="/ambulante-presenta"
          title="Presenta"
          description="Exhibición gratuita de películas todo el año"
          accentColor="presenta"
        />
        <InititativeListing
          image="/initiatives/mas-alla.png"
          link="/ambulante-mas-alla"
          title="Más Allá"
          description="Capacitación en producción documental"
          accentColor="mas-alla"
        />
        <InititativeListing
          image="/initiatives/ediciones.png"
          link="/ambulante-ediciones"
          title="Ediciones"
          description="Obras para expandir la cultura del cine"
          accentColor="ediciones"
        />
        <div className="hidden">
          <Text.Body
            as="a"
            href="/iniciativas"
            size="lg"
            className="cursor-pointer border-b-4 border-neutral-200 hover:border-neutral-400"
          >
            Ver todas las iniciativas
          </Text.Body>
        </div>
      </div>
      {!showAllInitiatives &&
        (globalConfiguration?.Incidencias ||
          globalConfiguration?.distribucion ||
          globalConfiguration?.becas_fondos ||
          globalConfiguration?.proyectos_especiales) ? (
        <div
          className="mt-2 flex items-center justify-center"
          onClick={() => setShowAllInitiatives(true)}
        >
          <Text.Body
            size="lg"
            className="cursor-pointer border-b-4 border-neutral-200 hover:border-neutral-400"
          >
            Ver todas las iniciativas
          </Text.Body>
        </div>
      ) : (
        <>
          {showAllInitiatives && <hr className="my-2 border"></hr>}
          <div className="grid-cols-2 gap-4 space-y-1 lg:grid lg:space-y-0">
            {globalConfiguration?.Incidencias && (
              <InititativeListing
                image="/initiatives/incidencias.png"
                link="/incidencia"
                title="Incidencias"
                description="Campañas sobre causas que merecen ser acompañadas"
                accentColor="incidencias"
              />
            )}
            {globalConfiguration?.distribucion && (
              <InititativeListing
                image="/initiatives/distribucion.png"
                link="/distribucion"
                title="Distribución"
                description="Consolidando la oferta de cine documental en México"
                accentColor="distribucion"
              />
            )}
            {globalConfiguration?.becas_fondos && (
              <InititativeListing
                image="/initiatives/becas-y-fondos.png"
                link="/becas-y-fondos"
                title="Becas y Fondos"
                description="Apoyamos la producción de cine en México"
                accentColor="becasyfondos"
              />
            )}
            {globalConfiguration?.proyectos_especiales && (
              <InititativeListing
                image="/initiatives/proyectos-especiales.png"
                link="/proyectos-especiales"
                title="Proyectos Especiales"
                description="Otros ciclos de exhibición, circuitos y colaboraciones"
                accentColor="proyectos-especiales"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

function InititativeListing({
  image = "https://via.placeholder.com/150",
  accentColor = "gira",
  title = "",
  description = "",
  link = "",
  active = false,
}) {
  const css = classNames(
    "min-h-[70px] flex align-middle items-center border-l-8 pl-2 hover:bg-neutral-100 cursor-pointer",
    accentColor ? `border-${accentColor}` : "border-red-500"
  );

  const focusClasses = {
    gira: "active:bg-gira",
    presenta: "active:bg-presenta",
    "mas-alla": "active:bg-mas-alla",
    ediciones: "active:bg-ediciones",
    incidencias: "active:bg-incidencias",
    distribucion: "active:bg-distribucion",
    becasyfondos: "active:bg-becasyfondos",
  };

  const focus = `active:bg-${accentColor}`;
  return (
    <div>
      <Link href={link} passHref>
        <div className={classNames(css, focusClasses[accentColor])}>
          {/* mock image */}
          <div className="hidden items-center  align-middle lg:flex">
            <Image
              src={image}
              alt="initiative"
              width={60}
              height={60}
              className="hidden max-w-full rounded object-cover lg:block"
            />
          </div>
          <div className="flex flex-1 flex-col px-1 py-2 md:px-5 ">
            <Text.Title
              as="p"
              size="md"
              className="flex items-center text-neutral-900"
            >
              {title}&nbsp;
              {active && (
                <Text.Overline
                  size="sm"
                  className="ml-2 rounded-sm bg-gira p-1 text-white"
                >
                  Ahora
                </Text.Overline>
              )}
            </Text.Title>
            <Text.Body size="md" className="text-neutal-600">
              {description}
            </Text.Body>
          </div>
        </div>
      </Link>
    </div>
  );
}
