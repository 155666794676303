/* eslint-disable react/jsx-key */
import React, { useState, useContext } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import PropTypes from "prop-types";
import classNames from "classnames";
import { SearchContext } from "@/components/lib/SearchBar";

import { Label } from "@/components/text/Label";
import { SearchIcon } from "@heroicons/react/outline";
import { GlobalLogo } from "../lib/SvgLogo";
import PopoverMenu from "../lib/PopoverMenu";
import Hamburger from "./Hamburger";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import PopSubMenu from "./PopSubMenu";
import { Popover, Transition } from "@headlessui/react";
import InitiativesPopover from "./InitiativesPopover";

const searchIconStyles = {
  dark: "text-white hover:text-neutral-800",
  light: "text-neutral-800 hover:text-white",
};

const dark_styles =
  "text-white/[.80]  border-b-4 border-b-transparent box-border border-b-4  hover:border-b-white ";
const light_styles =
  "text-neutral-700  border-b-4 border-b-transparent box-border border-b-4 hover:border-b-neutral-1000";

const themes = {
  light: light_styles,
  dark: dark_styles,
};
const linksStyles = {
  dark: "hover:bg-neutral-200",
  light: "hover:bg-white/80",
};
function LanguageSelector({ theme }) {
  const [selected, setSelected] = useState("ES");

  const unselectedStyle = "bg-neutral-100/50 p-1 font-bold text-neutral-300";

  return (
    <div className="flex ">
      <button
        onClick={() => setSelected("ES")}
        className={twMerge(
          "w-7 border-[2px] border-r-0 border-neutral-400 p-1 text-[12px] font-bold",
          selected !== "ES" && unselectedStyle
        )}
      >
        ES
      </button>
      <button
        onClick={() => setSelected("EN")}
        className={twMerge(
          "w-7 border-[2px] border-neutral-400  p-1 text-[12px] font-bold",
          selected !== "EN" && unselectedStyle
        )}
      >
        EN
      </button>
    </div>
  );
}
export default function Navbar({
  theme = "dark",
  items = navConfig,
  className,
}) {
  const router = useRouter();
  const [open, setOpen] = useState(true);
  const base_styles = " flex gap-5 items-center p-3 lg:justify-center";

  const border =
    theme === "light" ? "border-t-base-200" : "border-t-white/[.36]";

  const css = classNames(themes[theme], "px-3, py-4");
  return (
    <div className="flex w-full gap-5 md:justify-start  md:align-middle lg:items-center">
      {/* <PopoverMenu theme="light" /> */}
      <div className="">
        <Link href="/" className="flex items-center">
          <GlobalLogo
            className={classNames(
              "sm:w-[188px] lg:w-[204px] ",
              theme === "light" ? "text-neutral-900" : "text-white"
            )}
          />
        </Link>
      </div>
      <nav className={classNames(className, "hidden grow lg:block ")}>
        <div className={classNames(base_styles, border)}>
          {items.map((e, i) =>
            e.label === "Iniciativas" ? (
              <Popover key={i} as={React.Fragment}>
                {({ open }) => (
                  <>
                    <Popover.Button
                      key={i}
                      size="lg"
                      className={classNames(
                        css,
                        "relative inline-flex cursor-pointer items-center px-2",
                        router.pathname.includes("iniciativa")
                          ? " bg-black text-white"
                          : ""
                      )}
                    >
                      {e.label}
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="ml-1 h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="ml-1 h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </Popover.Button>

                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel
                        as="div"
                        className="absolute top-14 z-[90] w-[800px]  bg-white p-10 "
                      >
                        <InitiativesPopover />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : (
              <a
                onMouseEnter={() => setOpen(false)}
                key={i}
                href={e.href}
                onClick={e.onClick}
                className={classNames(
                  themes[theme],
                  "px-3, py-4",
                  theme === "light"
                    ? "hover:bg-black hover:text-white"
                    : "hover:bg-white/80 hover:text-black ",
                  router.pathname.includes(e.href) ? " bg-black text-white" : ""
                )}
              >
                <Label size="lg" className=" px-2">
                  {e.label}
                </Label>
              </a>
            )
          )}
        </div>
      </nav>
    </div>
  );
}

export function Nav({ theme }) {
  const search_context = useContext(SearchContext);

  return (
    <>
      <div className="flex h-12 grow items-center align-middle md:h-[50px] lg:h-[76px]  ">
        <Navbar theme={theme} />
        <div
          className={classNames(
            theme === "light" ? "text-neutral-800" : "text-white",
            " flex h-full  cursor-pointer items-center px-3 align-middle hover:bg-black hover:text-white "
          )}
          onClick={() => search_context.setDisplay(!search_context.display)}
        >
          <SearchIcon className="h-6 w-6 " />
        </div>
        <Hamburger options={navConfig} theme={theme} />
      </div>
    </>
  );
}

export function AmbulanteNav({ theme }) {
  const search_context = useContext(SearchContext);

  const linksStyle = twMerge(
    "cursor-pointer px-0 align-middle flex",
    linksStyles[theme]
  );

  const searchStyle = twMerge("w-5", searchIconStyles[theme]);

  return (
    <>
      <div className="flex h-12 grow items-center sm:mx-4 sm:gap-4 md:mx-8 md:h-[50px] lg:mx-[116px] lg:h-[76px] xl:mx-auto xl:max-w-[1208px]  ">
        <Navbar theme={theme} />
        <div
          onClick={() => search_context.setDisplay(!search_context.display)}
          className={linksStyle}
        >
          <SearchIcon className={searchStyle} />
        </div>
        {/* <LanguageSelector theme={theme} /> */}
        <Hamburger options={navConfig} theme={theme} />
      </div>
    </>
  );
}

Navbar.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
};

export const navConfig = [
  {
    label: "Iniciativas",
    href: "/iniciativas/gira",
    onClick: null,
    chevron: true,
  },
  {
    label: "Calendario",
    href: "/calendario",
    onClick: null,
    chevron: true,
  },
  {
    label: "Películas",
    href: "/catalogo",
    onClick: null,
    chevron: true,
  },
  {
    label: "Blog",
    href: "/blog",
    onClick: null,
    chevron: true,
  },
  {
    label: "Aliados",
    href: "/aliados",
    onClick: null,
    chevron: true,
  },
];
